import React from 'react';
import AppRoutes from './routes/AppRoutes';


const App = () => {
  return (
    <AppRoutes/>
  );
};

export default App;


