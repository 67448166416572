import React from "react";

import '../../styling/HowWeHelp.css';

const HowWeCanHelp = () => {

    return (

        <>
            <div className='about-help'>
                <div className="about-help-heading">
                    <h1>How we can help</h1>
                </div>
                <div className='about-help-text'>
                    <p>At Barrett Software, we are dedicated to providing comprehensive digital solutions tailored to meet the unique needs of your business. Our team of experienced professionals combines creativity,
                        technical expertise, and strategic thinking to deliver outstanding results across a range of services.</p>
                </div>
            </div>
        </>
    )
}

export default HowWeCanHelp;
